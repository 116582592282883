import * as React from 'react'

import { cn } from '~/utils/misc.tsx'

const Accordion = ({ children }: { children: React.ReactNode }) => {
	return <div>{children}</div>
}

const AccordionItem = ({
	children,
	className,
	isLast,
}: {
	children: React.ReactNode
	className?: string
}) => {
	return (
		<div className={cn({ 'border-b': !isLast }, className)}>{children}</div>
	)
}

const AccordionTrigger = ({
	className,
	children,
	isOpen,
	onClick,
}: {
	className?: string
	children: React.ReactNode
	isOpen: boolean
	onClick: () => void
}) => {
	return (
		<div
			onClick={onClick}
			className={cn(
				'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all',
				className,
			)}
		>
			{children}
		</div>
	)
}

const AccordionContent = ({
	className,
	children,
	isOpen,
}: {
	className?: string
	children: React.ReactNode
	isOpen: boolean
}) => {
	return (
		<div
			className={cn(
				'overflow-hidden text-sm transition-[max-height] ease-in-out',
				isOpen ? 'max-h-screen duration-300' : 'max-h-0 duration-0',
				className,
			)}
		>
			<div className="pb-4 pt-0">{children}</div>
		</div>
	)
}

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
