import { ChevronDownIcon } from 'lucide-react'
import React from 'react'

import { isKanji, toRomaji } from 'wanakana'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '~/components/ui/accordion'
import { Badge } from '~/components/ui/badge'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '~/components/ui/tooltip'

import { combineFuri } from '~/utils/combine-furi'
import { cn } from '~/utils/misc'
import { TAGS } from '~/utils/tags'

export const PartOfSpeech: React.FC<{ pos: string }> = ({ pos }) => {
	const tag = TAGS.find((t) => t[0] === pos)
	if (!tag) {
		return <Badge>{pos}</Badge>
	}
	return (
		<Tooltip>
			<TooltipTrigger>
				<Badge>{pos}</Badge>
			</TooltipTrigger>
			<TooltipContent>{tag[3]}</TooltipContent>
		</Tooltip>
	)
}

const SearchResults = ({ termGroups, romaji = false }) => {
	const [openIndex, setOpenIndex] = React.useState<number | null>(0)

	const toggleItem = (index: number) => {
		setOpenIndex(openIndex === index ? null : index)
	}

	return (
		<div className="mx-4 mb-[250px] mt-4 max-w-lg rounded-lg bg-white p-6 shadow-md dark:border dark:border-input dark:bg-black dark:text-white md:mx-auto md:mt-20">
			<div className="space-y-10">
				<Accordion>
					{termGroups.map(({ uniqueIdentifier, group }, index) => {
						const topTerm = group[0]

						if (!topTerm) {
							return null
						}

						return (
							<AccordionItem
								isLast={index === termGroups.length - 1}
								key={uniqueIdentifier}
							>
								<AccordionTrigger
									isOpen={openIndex === index}
									onClick={() => toggleItem(index)}
								>
									<div className="flex items-center space-x-4">
										{group.map((g, gIndex) => {
											const pairs = combineFuri(g.term, g.reading)
											return (
												<span
													lang="ja"
													className={cn(
														'font-japanese inline-flex flex-wrap p-1 text-gray-900 dark:text-gray-100',
														{
															'rounded border bg-secondary dark:border-input dark:text-gray-500':
																gIndex !== 0,
														},
													)}
													key={g.id}
												>
													{pairs.map(([furiText, text], index: number) => (
														<span
															lang="ja"
															key={text + index}
															className="inline-flex flex-col items-center justify-end self-end font-serif text-3xl font-bold leading-none"
														>
															<span
																lang="ja"
																className="mx-[0.1em] block select-none pb-[0.1em] pt-[0.2em] text-[0.5em] tracking-[-0.02em] opacity-90"
															>
																{furiText}
															</span>
															<span lang="ja" className="block">
																{text}
															</span>
															{romaji && (
																<span
																	lang="ja"
																	className="mx-[0.1em] block select-none pb-[0.1em] pt-[0.2em] text-[0.5em] tracking-[-0.02em] opacity-90"
																>
																	{isKanji(text)
																		? toRomaji(furiText)
																		: toRomaji(text)}
																</span>
															)}
														</span>
													))}
												</span>
											)
										})}
									</div>
									<div className="flex items-center space-x-4">
										{topTerm.jlptLevel && (
											<Tooltip>
												<TooltipTrigger className="">
													<Badge variant="gradient">{`N${topTerm.jlptLevel}`}</Badge>
												</TooltipTrigger>
												<TooltipContent>
													<p>The JLPT level of the word</p>
												</TooltipContent>
											</Tooltip>
										)}
										{topTerm.frequencyLevel && (
											<Tooltip>
												<TooltipTrigger>
													<Badge
														className="mb-1.5"
														variant={
															topTerm.frequencyLevel <= 5
																? 'diamond'
																: 'diamond-dull'
														}
													>{`${topTerm.frequencyLevel}`}</Badge>
												</TooltipTrigger>
												<TooltipContent>
													<p>
														Our ranking from 1 to 10 of how useful the word is
													</p>
												</TooltipContent>
											</Tooltip>
										)}
										{termGroups.length > 1 && (
											<ChevronDownIcon
												className={`h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ${
													openIndex === index && 'rotate-180'
												}`}
											/>
										)}
									</div>
								</AccordionTrigger>
								<AccordionContent isOpen={openIndex === index}>
									<div className="flex items-center justify-between py-1">
										<div className="flex space-x-2">
											{topTerm.partOfSpeech.split(' ').map((pos) => (
												<PartOfSpeech key={pos} pos={pos} />
											))}
										</div>
									</div>
									<div className="md:text-md space-y-3 text-sm">
										{topTerm.definitions &&
											JSON.parse(topTerm.definitions).map(
												(definition: string, index: number) => (
													<div key={index} className="flex space-x-2">
														<span className="font-serif text-gray-700 dark:text-gray-300">
															{index + 1}.
														</span>
														<p className="font-serif text-gray-800 dark:text-gray-200">
															{definition}
														</p>
													</div>
												),
											)}
									</div>
									<div className="md:text-md space-y-3 text-sm">
										{topTerm.examples &&
											JSON.parse(topTerm.examples).map(
												(example: string, index: number) => (
													<div key={index} className="flex space-x-2 pt-4">
														<h2>Example: </h2>
														<p className="font-serif text-gray-800 dark:text-gray-200">
															{example}
														</p>
													</div>
												),
											)}
									</div>
								</AccordionContent>
							</AccordionItem>
						)
					})}
				</Accordion>
			</div>
		</div>
	)
}

export default SearchResults
