export const TAGS = [
	['news', 'frequent', -2, 'appears frequently in Mainichi Shimbun', 0],
	['ichi', 'frequent', -2, 'listed as common in Ichimango Goi Bunruishuu', 0],
	['spec', 'frequent', -2, 'common words not included in frequency lists', 0],
	['gai', 'frequent', -2, 'common loanword', 0],
	['P', 'popular', -10, 'popular term', 10],
	['male', '', 0, 'male term or language', 0],
	['adj-nari', 'partOfSpeech', -3, 'archaic/formal form of na-adjective', 0],
	['v4g', '', 0, "Yodan verb with `gu' ending (archaic)", 0],
	['adj-f', 'partOfSpeech', -3, 'noun or verb acting prenominally', 0],
	['aux', 'partOfSpeech', -3, 'auxiliary', 0],
	['Buddh', '', 0, 'Buddhist term', 0],
	['ctr', 'partOfSpeech', -3, 'counter', 0],
	['geom', '', 0, 'geometry term', 0],
	['v4b', 'partOfSpeech', -3, "Yodan verb with `bu' ending (archaic)", 0],
	[
		'v2z-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `zu' ending (archaic)",
		0,
	],
	[
		'v2n-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `nu' ending (archaic)",
		0,
	],
	['n-adv', 'partOfSpeech', -3, 'adverbial noun (fukushitekimeishi)', 0],
	[
		'v2g-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `gu' ending (archaic)",
		0,
	],
	['sumo', '', 0, 'sumo term', 0],
	[
		'v2k-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `ku' ending (archaic)",
		0,
	],
	[
		'X',
		'',
		0,
		'rude or X-rated term (not displayed in educational software)',
		0,
	],
	['ik', '', 0, 'word containing irregular kana usage', -5],
	['ok', '', 0, 'out-dated or obsolete kana usage', -5],
	['yoji', '', 0, 'yojijukugo', 0],
	['n-pr', 'partOfSpeech', -3, 'proper noun', 0],
	['ling', '', 0, 'linguistics terminology', 0],
	['uk', '', 0, 'word usually written using kana alone', 0],
	['v4h', 'partOfSpeech', -3, "Yodan verb with `hu/fu' ending (archaic)", 0],
	['med', '', 0, 'medicine, etc. term', 0],
	['anat', '', 0, 'anatomical term', 0],
	['v5n', 'partOfSpeech', -3, "Godan verb with `nu' ending", 0],
	['v-unspec', '', 0, 'verb unspecified', 0],
	[
		'v2g-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `gu' ending (archaic)",
		0,
	],
	['adj-pn', 'partOfSpeech', -3, 'pre-noun adjectival (rentaishi)', 0],
	['adj-t', 'partOfSpeech', -3, "`taru' adjective", 0],
	['derog', '', 0, 'derogatory', 0],
	['int', 'partOfSpeech', -3, 'interjection (kandoushi)', 0],
	['v4r', 'partOfSpeech', -3, "Yodan verb with `ru' ending (archaic)", 0],
	[
		'v2t-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `tsu' ending (archaic)",
		0,
	],
	['n-suf', 'partOfSpeech', -3, 'noun, used as a suffix', 0],
	['uK', '', 0, 'word usually written using kanji alone', 0],
	['v4n', '', 0, "Yodan verb with `nu' ending (archaic)", 0],
	[
		'v2s-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `su' ending (archaic)",
		0,
	],
	['iK', '', 0, 'word containing irregular kanji usage', -5],
	['sl', '', 0, 'slang', 0],
	['v5k', 'partOfSpeech', -3, "Godan verb with `ku' ending", 0],
	['v5u', 'partOfSpeech', -3, "Godan verb with `u' ending", 0],
	['proverb', '', 0, 'proverb', 0],
	['prt', 'partOfSpeech', -3, 'particle', 0],
	['v5uru', '', 0, 'Godan verb - Uru old class verb (old form of Eru)', 0],
	['shogi', '', 0, 'shogi term', 0],
	['rkb', '', 0, 'Ryuukyuu-ben', 0],
	['geol', '', 0, 'geology, etc. term', 0],
	['law', '', 0, 'law, etc. term', 0],
	['conj', 'partOfSpeech', -3, 'conjunction', 0],
	['fam', '', 0, 'familiar language', 0],
	['poet', '', 0, 'poetical term', 0],
	['v5aru', 'partOfSpeech', -3, 'Godan verb - -aru special class', 0],
	[
		'vs',
		'partOfSpeech',
		-3,
		'noun or participle which takes the aux. verb suru',
		0,
	],
	['bus', '', 0, 'business term', 0],
	['eK', '', 0, 'exclusively kanji', 0],
	['num', 'partOfSpeech', -3, 'numeric', 0],
	['pn', 'partOfSpeech', -3, 'pronoun', 0],
	['vs-s', 'partOfSpeech', -3, 'suru verb - special class', 0],
	[
		'v2h-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `hu/fu' ending (archaic)",
		0,
	],
	['oik', '', 0, 'old or irregular kana form', -5],
	['v5s', 'partOfSpeech', -3, "Godan verb with `su' ending", 0],
	['vk', 'partOfSpeech', -3, 'Kuru verb - special class', 0],
	['MA', '', 0, 'martial arts term', 0],
	['adv', 'partOfSpeech', -3, 'adverb (fukushi)', 0],
	['adv-to', 'partOfSpeech', -3, "adverb taking the `to' particle", 0],
	['comp', '', 0, 'computer terminology', 0],
	['hon', '', 0, 'honorific or respectful (sonkeigo) language', 0],
	['adj-kari', '', 0, "`kari' adjective (archaic)", 0],
	['v2m-k', '', 0, "Nidan verb (upper class) with `mu' ending (archaic)", 0],
	['sports', '', 0, 'sports term', 0],
	['tsug', '', 0, 'Tsugaru-ben', 0],
	['v4s', 'partOfSpeech', -3, "Yodan verb with `su' ending (archaic)", 0],
	['bot', '', 0, 'botany term', 0],
	['arch', 'archaism', -4, 'archaism', 0],
	['chem', '', 0, 'chemistry term', 0],
	[
		'gikun',
		'',
		0,
		'gikun (meaning as reading) or jukujikun (special kanji reading)',
		0,
	],
	['v5r', 'partOfSpeech', -3, "Godan verb with `ru' ending", 0],
	['vr', 'partOfSpeech', -3, 'irregular ru verb, plain form ends with -ri', 0],
	['pref', 'partOfSpeech', -3, 'prefix', 0],
	[
		'v5u-s',
		'partOfSpeech',
		-3,
		"Godan verb with `u' ending (special class)",
		0,
	],
	['v4t', 'partOfSpeech', -3, "Yodan verb with `tsu' ending (archaic)", 0],
	[
		'v2r-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `ru' ending (archaic)",
		0,
	],
	['unc', 'partOfSpeech', -3, 'unclassified', 0],
	[
		'v2b-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `bu' ending (archaic)",
		0,
	],
	['econ', '', 0, 'economics term', 0],
	['adj-i', 'partOfSpeech', -3, 'adjective (keiyoushi)', 0],
	['id', 'expression', -5, 'idiomatic expression', 0],
	['m-sl', '', 0, 'manga slang', 0],
	['obs', '', 0, 'obsolete term', 0],
	['physics', '', 0, 'physics terminology', 0],
	['music', '', 0, 'music term', 0],
	['astron', '', 0, 'astronomy, etc. term', 0],
	[
		'adj-na',
		'partOfSpeech',
		-3,
		'adjectival nouns or quasi-adjectives (keiyodoshi)',
		0,
	],
	['hum', '', 0, 'humble (kenjougo) language', 0],
	['v1-s', 'partOfSpeech', -3, 'Ichidan verb - kureru special class', 0],
	[
		'v5r-i',
		'partOfSpeech',
		-3,
		"Godan verb with `ru' ending (irregular verb)",
		0,
	],
	['v5t', 'partOfSpeech', -3, "Godan verb with `tsu' ending", 0],
	['ek', '', 0, 'exclusively kana', 0],
	['v5g', 'partOfSpeech', -3, "Godan verb with `gu' ending", 0],
	['vs-i', 'partOfSpeech', -3, 'suru verb - irregular', 0],
	[
		'v2y-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `yu' ending (archaic)",
		0,
	],
	[
		'v2h-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `hu/fu' ending (archaic)",
		0,
	],
	['exp', 'expression', -5, 'expressions (phrases, clauses, etc.)', 0],
	['vi', 'partOfSpeech', -3, 'intransitive verb', 0],
	['ktb', '', 0, 'Kantou-ben', 0],
	['tsb', '', 0, 'Tosa-ben', 0],
	['nab', '', 0, 'Nagano-ben', 0],
	['vn', 'partOfSpeech', -3, 'irregular nu verb', 0],
	['vt', 'partOfSpeech', -3, 'transitive verb', 0],
	['vulg', '', 0, 'vulgar expression or word', 0],
	['baseb', '', 0, 'baseball term', 0],
	['zool', '', 0, 'zoology term', 0],
	['mahj', '', 0, 'mahjong term', 0],
	['iv', '', 0, 'irregular verb', 0],
	['n', 'partOfSpeech', -3, 'noun (common) (futsuumeishi)', 0],
	['vs-c', 'partOfSpeech', -3, 'su verb - precursor to the modern suru', 0],
	[
		'v2m-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `mu' ending (archaic)",
		0,
	],
	['archit', '', 0, 'architecture term', 0],
	['adj-ku', 'partOfSpeech', -3, "`ku' adjective (archaic)", 0],
	['aux-adj', 'partOfSpeech', -3, 'auxiliary adjective', 0],
	['oK', '', 0, 'word containing out-dated kanji', -5],
	['sens', '', 0, 'sensitive', 0],
	['suf', 'partOfSpeech', -3, 'suffix', 0],
	['v5m', 'partOfSpeech', -3, "Godan verb with `mu' ending", 0],
	[
		'adj-no',
		'partOfSpeech',
		-3,
		"nouns which may take the genitive case particle `no'",
		0,
	],
	['male-sl', '', 0, 'male slang', 0],
	['v4k', 'partOfSpeech', -3, "Yodan verb with `ku' ending (archaic)", 0],
	[
		'v2y-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `yu' ending (archaic)",
		0,
	],
	['finc', '', 0, 'finance term', 0],
	['engr', '', 0, 'engineering term', 0],
	['hob', '', 0, 'Hokkaido-ben', 0],
	['adj-shiku', 'partOfSpeech', -3, "`shiku' adjective (archaic)", 0],
	[
		'v2k-k',
		'partOfSpeech',
		-3,
		"Nidan verb (upper class) with `ku' ending (archaic)",
		0,
	],
	[
		'v2t-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `tsu' ending (archaic)",
		0,
	],
	[
		'v2r-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `ru' ending (archaic)",
		0,
	],
	['joc', '', 0, 'jocular, humorous term', 0],
	['aux-v', 'partOfSpeech', -3, 'auxiliary verb', 0],
	['v4m', 'partOfSpeech', -3, "Yodan verb with `mu' ending (archaic)", 0],
	['v2d-k', '', 0, "Nidan verb (upper class) with `dzu' ending (archaic)", 0],
	['v2b-s', '', 0, "Nidan verb (lower class) with `bu' ending (archaic)", 0],
	['biol', '', 0, 'biology term', 0],
	['Shinto', '', 0, 'Shinto term', 0],
	['adj-ix', 'partOfSpeech', -3, 'adjective (keiyoushi) - yoi/ii class', 0],
	['rare', '', 0, 'rare', 0],
	['v5k-s', 'partOfSpeech', -3, 'Godan verb - Iku/Yuku special class', 0],
	['osb', '', 0, 'Osaka-ben', 0],
	['kyu', '', 0, 'Kyuushuu-ben', 0],
	['kyb', '', 0, 'Kyoto-ben', 0],
	['chn', '', 0, "children's language", 0],
	['math', '', 0, 'mathematics', 0],
	['n-pref', 'partOfSpeech', -3, 'noun, used as a prefix', 0],
	['n-t', 'partOfSpeech', -3, 'noun (temporal) (jisoumeishi)', 0],
	['pol', '', 0, 'polite (teineigo) language', 0],
	['ateji', '', 0, 'ateji (phonetic) reading', 0],
	['food', '', 0, 'food term', 0],
	['v5b', 'partOfSpeech', -3, "Godan verb with `bu' ending", 0],
	[
		'v2d-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `dzu' ending (archaic)",
		0,
	],
	[
		'v2w-s',
		'partOfSpeech',
		-3,
		"Nidan verb (lower class) with `u' ending and `we' conjugation (archaic)",
		0,
	],
	['abbr', '', 0, 'abbreviation', 0],
	['cop-da', 'partOfSpeech', -3, 'copula', 0],
	['v2a-s', 'partOfSpeech', -3, "Nidan verb with 'u' ending (archaic)", 0],
	['thb', '', 0, 'Touhoku-ben', 0],
	[
		'vz',
		'partOfSpeech',
		-3,
		'Ichidan verb - zuru verb (alternative form of -jiru verbs)',
		0,
	],
	['io', '', 0, 'irregular okurigana usage', -5],
	['mil', '', 0, 'military', 0],
	['obsc', '', 0, 'obscure term', 0],
	['on-mim', '', 0, 'onomatopoeic or mimetic word', 0],
	['ksb', '', 0, 'Kansai-ben', 0],
	['col', '', 0, 'colloquialism', 0],
	['fem', '', 0, 'female term or language', 0],
	['v1', 'partOfSpeech', -3, 'Ichidan verb', 0],
]
