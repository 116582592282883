import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '~/utils/misc.tsx'

const badgeVariants = cva(
	'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
				secondary:
					'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
				destructive:
					'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
				outline: 'text-muted-foreground bg-secondary',
				gradient:
					'p-1.5 text-gray-700 dark:text-gray-300 rounded-md shadow-sm overflow-hidden relative w-min h-min bg-gradient-to-b from-[#f0f0f0] to-[#dbdbdb] dark:from-[#303030] dark:to-[#1a1a1a] border border-solid border-[rgba(0,0,0,0.05)] dark:border-[rgba(255,255,255,0.1)]',
				diamond:
					'relative p-3 text-black top-1.5 overflow-hidden inline-block transform rotate-45 bg-gradient-to-b from-[#ffd700] to-[#b29500] border border-solid border-[rgba(212,175,55,0.5)] dark:border-[rgba(212,175,55,0.7)]',
				'diamond-dull':
					'relative p-3 text-gray-700 top-1.5 dark:text-gray-300 overflow-hidden inline-block transform rotate-45 bg-gradient-to-b from-[#f0f0f0] to-[#dbdbdb] dark:from-[#303030] dark:to-[#1a1a1a] border border-solid border-[rgba(0,0,0,0.05)] dark:border-[rgba(255,255,255,0.1)]',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
	return (
		<div className={cn(badgeVariants({ variant }), className)} {...props}>
			{variant && variant.includes('diamond') ? (
				<span className="absolute inset-0 flex rotate-[-45deg] transform items-center justify-center text-center">
					{props.children}
				</span>
			) : (
				props.children
			)}
		</div>
	)
}

export { Badge, badgeVariants }
